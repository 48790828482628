import React from "react"
import ConfigContext from "./utils/configContext"

class Description extends React.Component {
  static contextType = ConfigContext

  state = {
    showDescription: false,
  }

  toggleShowDescription = () => {
    this.setState(state => {
      return { showDescription: !state.showDescription }
    })
  }

  render() {
    let { showDescription } = this.state
    return (
      <>
        <div>
          {!showDescription ? (
            <p className="is-capitalized playColor has-text-weight-semibold is-size-7 has-text-centered">
              <span
                onClick={this.toggleShowDescription}
                onKeyDown={this.toggleShowDescription}
                role="button"
                tabIndex="0"
                className="is-pointer"
              >
                READ MORE
              </span>
            </p>
          ) : null}
          {showDescription ? (
            <div>
              <div className="content is-size-7">
                <ul>
                  {[
                    ...(this.context.descriptionList || []),
                    ...(this.context.variantDescriptionList || []),
                  ].map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </div>
              <p className="is-capitalized playColor has-text-weight-semibold is-size-7 has-text-centered">
                <span
                  onClick={this.toggleShowDescription}
                  onKeyDown={this.toggleShowDescription}
                  role="button"
                  tabIndex="0"
                  className="is-pointer"
                >
                  COLLAPSE
                </span>
              </p>
            </div>
          ) : null}
        </div>
        <br />
      </>
    )
  }
}

export default Description
