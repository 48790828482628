export const getCarouselImages = (queryData) => {
  let imagesFromQuery = []

  for (let key in queryData.allFile.edges) {
    imagesFromQuery.push({
      img: queryData.allFile.edges[key].node.childImageSharp.gatsbyImageData,
      path: queryData.allFile.edges[key].node.relativePath.replace(
        "carousel/",
        ""
      ),
    })
  }

  imagesFromQuery.sort(((a, b) => {
    return ('' + a.path).localeCompare(b.path);
  }))

  return imagesFromQuery
}
