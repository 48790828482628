import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import CloseButton from "../images/close.svg"

import "../styles/Popup.css"
import ConfigContext from "./utils/configContext"

const Images = graphql`
  {
    heroes: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        relativeDirectory: { glob: "popup/hero/**" }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

class Popup extends React.Component {
  static contextType = ConfigContext

  constructor(props) {
    super(props)

    this.state = {
      minutes: "01",
      seconds: "30",
      downloadsCount: 3598,
    }
  }

  componentDidMount() {
    // start timer for 1.5 minutes
    this.startTimer(60 * 1.5)
  }

  componentWillUnmount() {
    clearInterval(this.state.countdownTimer)
  }

  startTimer = duration => {
    let timer = duration,
      minutes,
      seconds

    const countdownTimer = setInterval(() => {
      minutes = parseInt(timer / 60, 10)
      seconds = parseInt(timer % 60, 10)

      this.setState({
        minutes: minutes < 10 ? "0" + minutes : minutes,
        seconds: seconds < 10 ? "0" + seconds : seconds,
        downloadsCount:
          this.state.downloadsCount + (seconds % 10 === 0 ? 1 : 0),
      })

      if (--timer < 0) {
        this.props.closePopup()
      }
    }, 1000)
    this.setState({ countdownTimer })
  }

  render() {
    const { closePopup, installUrl } = this.props
    const { minutes, seconds, downloadsCount } = this.state

    return (
      <div id="backdrop">
        <div className="bg">
          <div id="popup">
            <div className="close">
              <div
                onClick={closePopup}
                onKeyDown={closePopup}
                role="button"
                tabIndex="0"
              >
                <img
                  src={CloseButton}
                  alt="close button"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <span
              style={{
                fontSize: "15px",
                textAlign: "center",
              }}
            >
              {this.context.popupTitle}
            </span>
            <span className="subtext">{this.context.popupText}</span>
            <div className="timer">
              <span>00:</span>
              {minutes}
              <span>:</span>
              {seconds}
            </div>
            <StaticQuery
              query={Images}
              render={data => {
                let fluidImgFromQuery = []
                for (let key in data.heroes.edges) {
                  fluidImgFromQuery.push({
                    img: data.heroes.edges[key].node.childImageSharp
                      .gatsbyImageData,
                    path: data.heroes.edges[key].node.relativePath.replace(
                      "popup/hero/",
                      ""
                    ),
                  })
                }

                const fluid = fluidImgFromQuery.find(
                  f => f.path === this.context.popupImage
                )

                return (
                  <div
                    style={{
                      width: "212px",
                    }}
                  >
                    <div>
                      <GatsbyImage image={fluid.img} alt="" />
                    </div>
                    <div style={{ marginTop: "16px" }}>
                      <a
                        onClick={() => {
                          closePopup()
                          this.props.fbEventAndShowModal("Popup Install Button")
                        }}
                        href={installUrl}
                        className="popup-install-btn"
                      >
                        Join Now
                      </a>
                    </div>
                  </div>
                )
              }}
            />
            <span
              style={{
                marginTop: "16px",
                fontFamily: '"Gotham Rounded Book" !important',
              }}
              className="downloads"
            >
              Today's downloads : {downloadsCount}
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default Popup
