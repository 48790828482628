import React from "react"
import "../styles/stats.css"
import star from "../images/star.svg"
import downloadIcon from "../images/download_icon.png"
import { generateUrl } from "./utils/generateUrl"
import ConfigContext from "./utils/configContext"

class Stats extends React.Component {
  static contextType = ConfigContext

  constructor(props) {
    super(props)
    this.state = {}
  }

  onClickHandler = eventSource => {
    this.props.fbEventAndShowModal(eventSource)
  }

  componentDidMount() {
    this.setState({
      installUrl: generateUrl(this.context.installUrl, this.context.forceUse),
    })
  }

  render() {
    const { installUrl } = this.state

    return (
      <>
        <div className="stats-reviews">
          <a href={"#reviews"}>
            <div className="ratingDiv">
              <p className="topText has-text-grey-darker">
                <span
                  style={{ marginRight: "-4px" }}
                  className="weight-700 is-size-7 has-text-grey-darker"
                >
                  4.7
                </span>
                <span className="icon has-text-grey-darker">
                  <img className="ratingStar" src={star} alt="" />
                </span>
              </p>
            </div>
            <p
              style={{ marginTop: "-4px" }}
              className="is-size-7 has-text-grey has-text-centered"
            >
              10K Reviews
            </p>
          </a>
        </div>

        <div className="stats-install">
          <a
            onClick={() => {
              this.onClickHandler("Icon Button")
            }}
            href={installUrl}
          >
            <div className="downloadDivParent">
              <div className="downloadDivChild weight-700">
                <img className="downloadIc" src={downloadIcon} alt="download" />
              </div>
            </div>
            <p className="is-size-7 has-text-grey has-text-centered">20 MB</p>
          </a>
        </div>
        {this.context.numOfDownloads && (
          <div className="stats-downloads">
            <p className="weight-700 is-size-7 has-text-grey-darker has-text-centered">
              {this.context.numOfDownloads}
            </p>
            <p className="is-size-7 has-text-grey has-text-centered">Downloads</p>
          </div>
        )}
      </>
    )
  }
}

export default Stats
