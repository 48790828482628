/*
 Add pages in this object
 All carousel images should go into src/images/carousel/
 All popup images should go into src/images/popup/hero/
 Add custom static reviews in src/static-reviews/staticReviews.js
*/
const pages = {
  "dot-dash": {
    gameName: "Dots & Dash",
    descriptionText:
      "Are you bored & alone? Do you miss playing with your friends? Don't worry, GetMega Dots & Dash is here! For the first time in the world, play Dots & Dash on videochat & chill with your friends, in the comfort of your home. Create your private table, play live games, and hang out with your friends on videochat! 5,00,000+ players currently live on India's only social real money entertainment platform! Don't wait, videochat with your friends now!",
    descriptionList: [
      "100% safe, secure & private Dots & Dash games for you & your friends.",
      "Talk, watch, laugh and play Dots & Dash together on videochat in your private rooms!",
      "24-hour chat support, priority treatment for Dots & Dash Players.",
      "Smooth experience, awesome gameplay & non-stop winnings.",
      "Seamless withdrawal of winnings to Paytm, UPI, bank account, etc.",
      "Invite friends & win real cash when they join!",
    ],
    carouselImages: [
      "dot-dash/1.jpg",
      "dot-dash/2.jpg",
      "dot-dash/3.jpg",
      "dot-dash/4.jpg",
      "dot-dash/5.jpg",
      "dot-dash/6.jpg",
    ],
    staticTags: ["Social", "Board Games"],
    reviewTags: ["Social", "Multiplayer", "Fun"],
    popupImage: "carrom-girl.png",
    popupTitle: "Your friends are waiting, invite them now.",
    popupText:
      "Mat karo wait, call your friends & ho jao set. Make your Dots & Dash room in:",
    vimeoVideoId: "537657601",
    gtagId: "AW-718511341",
    numOfDownloads: "500K+",
    variants: {
      default: {
        installUrl: "https://getmega.com/latest-social",
        forceUse: true,
        hostingSite: "download-app-dotdash-0",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
      affiliate: {
        installUrl: "https://affiliate_asiasouth1.getmegacdn.com/latest-social",
        forceUse: false,
        hostingSite: "affiliate-download-dotdash",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
    },
  },
  cards: {
    gameName: "",
    descriptionText:
      "Play Cards games on India’s most loved real money entertainment platform. 10000+ players are playing every day. Choose your contest / table, small stakes or big stakes. Unlimited Real Cash to be won!",
    descriptionList: [
      "Discover India’s biggest cards tables ♠️ ❤️",
      "Play with sirf 100% verified players, no fake profiles 👩 👨",
      "Choose stakes apni marzi ke - small, medium, large, & HUGE 🏆",
      "Khelo kabhi bhi, with non-stop 24-hours tables ⏳",
      "Kisi bhi issue ke liye high-priority chat support 👨🏽‍💻",
      "Support karo 100% made in India app 🇮🇳",
    ],
    carouselImages: [
      "cards/1.jpg",
      "cards/2.jpg",
      "cards/3.jpg",
      "cards/4.jpg",
      "cards/5.jpg",
      "cards/6.jpg",
    ],
    staticTags: ["Card Games", "Win real cash"],
    reviewTags: ["Fastest Withdrawals", "Genuine Players", "Addictive"],
    popupImage: "poker-girl.png",
    popupTitle: "Riya won ₹89,580 first week mein!",
    popupText:
      "Play high stakes poker to win bahut jyada cash. Next tournament starts in:",
    vimeoVideoId: "537657601",
    gtagId: "AW-718511341",
    airtableName: "Poker LP Reviews",
    airtableBaseId: "appNqwhCawaU6zPpv",
    staticReviews: "cards",
    numOfDownloads: "500K+",
    variants: {
      default: {
        installUrl:
          "https://affiliate_asiasouth1.getmegacdn.com/latest-stable-poker",
        forceUse: true,
        hostingSite: "download-app-cards",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
      affiliate: {
        installUrl: "https://getmega.com/latest",
        forceUse: true,
        hostingSite: "affiliate-download-app-cards",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
      affiliate_ab: {
        installUrl: "https://getmega.com/latest",
        forceUse: true,
        hostingSite: "download-affiliate-app-cards",
        showPrivacyPolicy: true,
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
        numOfDownloads: "50L+",
        makeReviewsCollapsable: true,
        showAppInfo: true,
        cms: true,
        airtableBaseId: "app63cd0Z5hGSJJMy",
      },
    },
  },
  rummy: {
    gameName: "",
    descriptionText:
      "Play Rummy on India’s most loved real money entertainment platform. 10,000+ players are playing every day. Choose your contest / table, small stakes or big stakes. Unlimited Real Cash to be won!",
    descriptionList: [
      "Discover India’s biggest Rummy tables ♠️ ❤️",
      "Play with sirf 100% verified players, no fake profiles 👩 👨",
      "Choose stakes apni marzi ke - small, medium, large, & HUGE 🏆",
      "Khelo kabhi bhi, with non-stop 24-hours tables ⏳",
      "Kisi bhi issue ke liye high-priority chat support 👨🏽‍💻",
      "Support karo 100% made in India app 🇮🇳",
    ],
    carouselImages: [
      "rummy/1.png",
      "rummy/2.png",
      "rummy/3.png",
      "rummy/4.png",
      "rummy/5.png",
      "rummy/6.png",
    ],
    staticTags: ["Card Games", "Win real cash"],
    reviewTags: ["Fastest Withdrawals", "Genuine Players", "Addictive"],
    popupImage: "rummy-girl.png",
    popupTitle: "Riya won ₹89,580 first week mein!",
    popupText:
      "Play rummy on BIG tables to win loads of cash. Next tournament starts in:",
    vimeoVideoId: "537657601",
    gtagId: "AW-718511341",
    airtableName: "Poker LP Reviews",
    airtableBaseId: "appzs5lZNAcpHlhyA",
    staticReviews: "rummy",
    numOfDownloads: "500K+",
    variants: {
      default: {
        installUrl:
          "https://affiliate_asiasouth1.getmegacdn.com/latest-stable-rummy",
        forceUse: true,
        hostingSite: "download-app-rummy",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
      affiliate: {
        installUrl: "https://getmega.com/latest",
        forceUse: true,
        hostingSite: "affiliate-download-app-rummy",
        disclaimer: "This app will be downloaded outside Google Play Store",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
    },
  },
  carrom: {
    gameName: "Carrom",
    descriptionText:
      "Play Carrom on India’s most loved real money entertainment platform. 10000+ players are playing every day. Choose your contest / table, small stakes or big stakes. Unlimited Real Cash to be won!",
    descriptionList: [
      "Only 100% genuine verified players",
      "Exciting rooms non stop: all night, all day",
      "Big tables, for Big League",
      "Seamless withdrawal to Paytm, Bank account etc",
      "Instant deposits, seamlessly",
      "24-hour chat support, priority treatment for Carrom Players",
    ],
    carouselImages: [
      "carrom/1.png",
      "carrom/2.png",
      "carrom/3.png",
      "carrom/4.png",
      "carrom/5.png",
      "carrom/6.png",
      "carrom/7.png",
      "carrom/8.png",
      "carrom/9.png",
    ],
    staticTags: ["Board Games", "Win real cash"],
    reviewTags: ["Fastest Withdrawals", "Genuine Players", "Addictive"],
    popupImage: "carrom-girl.png",
    popupTitle: "Sirf is Carrom game se Neha won ₹89,580 in 9 days",
    popupText:
      "Mat karo wait, show your carrom skills now. Next carrom tournament starts in:",
    vimeoVideoId: "427996732",
    gtagId: "UA-140605344-9",
    airtableName: "Poker LP Reviews",
    airtableBaseId: "appwRfYrq4MiRzouD",
    staticReviews: "carrom",
    numOfDownloads: "500K+",
    variants: {},
  },
  "carrom-videochat": {
    gameName: "Carrom",
    descriptionText:
      "Are you bored & alone? Do you miss playing with your friends? Don't worry, GetMega Carrom is here! For the first time in the world, play Carrom on videochat & chill with your friends, in the comfort of your home. Create your private table, play live games, and hang out with your friends on videochat! 5,00,000+ players currently live on India's only social real money entertainment platform! Don't wait, videochat with your friends now!",
    descriptionList: [
      "100% safe, secure & private Carrom games for you & your friends.",
      "Talk, watch, laugh and play Carrom together on videochat in your private rooms!",
      "24-hour chat support, priority treatment for Carrom Players.",
      "Smooth experience, awesome gameplay & non-stop winnings.",
      "Seamless withdrawal of winnings to Paytm, UPI, bank account, etc.",
      "Invite friends & win real cash when they join!",
    ],
    carouselImages: [
      "carrom-videochat/1.png",
      "carrom-videochat/2.png",
      "carrom-videochat/3.png",
      "carrom-videochat/4.png",
      "carrom-videochat/5.png",
      "carrom-videochat/6.png",
      "carrom-videochat/7.png",
    ],
    staticTags: ["Social", "Board Games"],
    reviewTags: ["Social", "Multiplayer", "Fun"],
    popupImage: "carrom-girl.png",
    popupTitle: "Your friends are waiting, invite them now.",
    popupText:
      "Mat karo wait, call your friends & ho jao set. Make your Carrom room in:",
    vimeoVideoId: "537657601",
    gtagId: "AW-718511341",
    airtableName: "Poker LP Reviews",
    airtableBaseId: "appwRfYrq4MiRzouD",
    staticReviews: "carrom",
    numOfDownloads: "500K+",
    variants: {
      default: {
        installUrl:
          "https://affiliate_asiasouth1.getmegacdn.com/latest-fb-carrom",
        forceUse: true,
        hostingSite: "download-app-carrom-videochat",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
      affiliate: {
        installUrl: "https://affiliate_asiasouth1.getmegacdn.com/latest-social",
        forceUse: false,
        hostingSite: "affiliate-carrom-videochat",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
      "getmega.in": {
        installUrl: "https://getmega.onelink.me/M6qa/1c8c3d05",
        forceUse: false,
        hostingSite: "download-app-carrom-videochat2",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
    },
  },
  pool: {
    gameName: "Pool",
    descriptionText:
      "Play Pool on India’s most loved real money entertainment platform. 10000+ players are playing every day. Choose your contest / table, small stakes or big stakes. Unlimited Real Cash to be won!",
    descriptionList: [
      "Only 100% genuine verified players",
      "Exciting rooms non stop: all night, all day",
      "Big tables, for Big League",
      "Seamless withdrawal to Paytm, Bank account etc",
      "Instant deposits, seamlessly",
      "24-hour chat support, priority treatment for Pool Players",
    ],
    carouselImages: [
      "pool/1.png",
      "pool/2.png",
      "pool/3.png",
      "pool/4.png",
      "pool/5.png",
      "pool/6.png",
      "pool/7.png",
      "pool/8.png",
      "pool/9.png",
      "pool/10.png",
    ],
    staticTags: ["Pool", "Win Real Cash"],
    reviewTags: ["Fastest Withdrawals", "Genuine Players", "Addictive"],
    popupImage: "pool-girl.png",
    popupTitle: "Riya won ₹89,580 first week mein!",
    popupText:
      "Play classy Pool to win tonnes of money. Next tournament starts in:",
    vimeoVideoId: "342926432",
    gtagId: "AW-718511341",
    numOfDownloads: "500K+",
    variants: {
      default: {
        installUrl:
          "https://affiliate_asiasouth1.getmegacdn.com/latest-fb-pool",
        forceUse: true,
        hostingSite: "download-app-pool",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
    },
  },
  fruitkaat: {
    gameName: "Fruit Kaat",
    descriptionText:
      "Play Fruit Kaat on India’s most loved real money entertainment platform. 10000+ players are playing every day. Unlimited Real Cash to be won!",
    descriptionList: [
      "Only 100% genuine verified players",
      "Exciting rooms non stop: all night, all day",
      "Big tables, for Big League",
      "Seamless withdrawal to Paytm, Bank account etc",
      "Instant deposits, seamlessly",
      "24-hour chat support, priority treatment for Fruit Kaat Players",
    ],
    carouselImages: [
      "fruitkaat/1.png",
      "fruitkaat/2.jpg",
      "fruitkaat/3.jpg",
      "fruitkaat/4.jpg",
      "fruitkaat/5.jpg",
      "fruitkaat/6.png",
      "fruitkaat/7.png",
    ],
    staticTags: ["Social", "Win Real Cash"],
    reviewTags: ["Fastest Withdrawals", "Genuine Players", "Addictive"],
    popupImage: "fruitkaat-girl.png",
    popupTitle: "Riya won ₹89,580 first week mein!",
    popupText:
      "Play classy Fruit Kaat to win tonnes of money. Next tournament starts in:",
    vimeoVideoId: "342926432",
    gtagId: "AW-718511341",
    airtableName: "Fruitkaat LP Reviews",
    airtableBaseId: "appWwwjouUr30XT1w",
    staticReviews: "fruitkaat",
    numOfDownloads: "500K+",
    variants: {
      default: {
        installUrl: "https://getmega.com/latest-social",
        forceUse: true,
        hostingSite: "download-app-fruitkaat",
        taboolaPixelTracking: true,
        outbrainPixelTracking: true,
        fbPixelTracking: true,
      },
    },
  },
}

module.exports = pages
