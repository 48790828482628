const defaultData = require("./static-reviews.json")

const staticRecords = defaultData.map(obj => obj.fields)

const rummyData = require("./rummy.json")

const rummyRecords = rummyData.map(obj => obj.fields)

const carromData = require("./carrom.json")

const carromRecords = carromData.map(obj => obj.fields)

const fruitkaatData = require("./fruitkaat.json")

const fruitkaatRecords = fruitkaatData.map(obj => obj.fields)

const cardsData = require("./cards.json")

const cardsRecords = cardsData.map(obj => obj.fields)

const getStaticReviews = page => {
  switch (page) {
    case "cards":
      return cardsRecords
    case "rummy":
      return rummyRecords
    case "carrom":
      return carromRecords
    case "fruitkaat":
      return fruitkaatRecords
    default:
      return staticRecords
  }
}

export { getStaticReviews }
