import React from "react"
import { graphql, StaticQuery } from "gatsby"
import withLocation from "./withLocation"
import Logo from "../images/logo.png"
import Stats from "./Stats"
import { generateUrl } from "./utils/generateUrl"
import ConfigContext from "./utils/configContext"

const Data = graphql`
  query {
    allGhostPost(
      sort: { order: ASC, fields: published_at }
      filter: { tags: { elemMatch: { slug: { in: "hash-landing_page" } } } }
    ) {
      edges {
        node {
          slug
          title
          html
          excerpt
        }
      }
    }
  }
`

class Header extends React.Component {
  static contextType = ConfigContext

  state = {
    isOpen: false,
    staticTags: this.context.staticTags || [],
    installUrl: "",
    loaded: false,
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      installUrl: generateUrl(this.context.installUrl, this.context.forceUse),
    })
    this.setState({ loaded: true })
  }

  onClickHandler = eventSource => {
    this.props.fbEventAndShowModal(eventSource)
  }

  render() {
    let { tag1, tag2, tag3 } = this.props.search
    let tags = []
    tag1 && tags.push(tag1)
    tag2 && tags.push(tag2)
    tag3 && tags.push(tag3)

    let tagsArr = tags.length !== 0 ? tags : this.state.staticTags
    let { installUrl, convertedTags, loaded } = this.state
    let { gameName } = this.props
    return (
      <>
        <StaticQuery
          query={Data}
          render={data => {
            if (this.context.cms && gameName) {
              const getAllPosts = data.allGhostPost.edges
              const currentPost = getAllPosts.filter(
                item => item.node.slug === gameName
              )
              if (
                typeof currentPost !== "undefined" &&
                currentPost.length > 0
              ) {
                const getTags = currentPost.map(function (i) {
                  return i.node.excerpt
                })

                const allTags = getTags[0].trim().split(",")

                convertedTags = ""
                allTags.forEach(function (value) {
                  convertedTags +=
                    "<span class='tag is-rounded'>" + value + "</span>"
                })
              }
            }

            return (
              <div className="header container">
                <div className="imageSection">
                  <div className="logo">
                    <a
                      onClick={() => {
                        this.onClickHandler("Logo Button")
                      }}
                      href={installUrl}
                      style={{
                        maxWidth: 70,
                      }}
                    >
                      <img src={Logo} alt="" />
                    </a>
                  </div>
                  <div className="app-name">
                    <a
                      onClick={() => {
                        this.onClickHandler("Title Button")
                      }}
                      href={installUrl}
                    >
                      <p className=" weight-500 size-med ">
                        GetMega {this.context.gameName}
                      </p>
                    </a>
                    <p
                      className="is-size-7 weight-500 developer"
                      style={{ cursor: "pointer" }}
                    >
                      MegaGames
                    </p>
                    <div className="developer">
                      {loaded &&
                        (this.context.cms && convertedTags ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: convertedTags,
                            }}
                          />
                        ) : (
                          <>
                            {tagsArr.map(tag => (
                              <span key={tag} className="tag is-rounded">
                                {tag}
                              </span>
                            ))}
                          </>
                        ))}
                    </div>
                  </div>
                </div>
                <div className="stats-container">
                  <Stats fbEventAndShowModal={this.props.fbEventAndShowModal} />
                </div>
                <div className="install-button">
                  <a
                    onClick={() => {
                      this.onClickHandler("Primary Install Button")
                    }}
                    href={installUrl}
                  >
                    <button size="is-small" className="weight-700">
                      INSTALL
                    </button>
                  </a>
                </div>
              </div>
            )
          }}
        />
      </>
    )
  }
}

export default withLocation(Header)
