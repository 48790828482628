import React from "react"
import Siema from "siema"
import Description from "./Description"
import "../styles/carousel.css"
import Gallery from "./Gallery"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import ConfigContext from "./utils/configContext"
import withLocation from "./withLocation"
import { getCarouselImages } from "./utils/getCarouselImages"

const Data = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        relativeDirectory: { glob: "carousel/*" }
      }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(width: 115, layout: CONSTRAINED)
          }
        }
      }
    }
    allGhostPost(
      sort: { order: ASC, fields: published_at }
      filter: { tags: { elemMatch: { slug: { in: "hash-landing_page" } } } }
    ) {
      edges {
        node {
          slug
          title
          html
          excerpt
        }
      }
    }
  }
`

class Carousel extends React.Component {
  static contextType = ConfigContext

  state = {
    desc: this.context.descriptionText,
    showGallery: false,
    slideToScroll: 1,
    showFullContent: false,
    imageGallery: [],
    loaded: false,
  }

  async componentDidMount() {
    setTimeout(function () {
      new Siema({
        perPage: 3,
      })
    }, 100)
    this.setState({ loaded: true })
  }

  showGallery = (e, index) => {
    e.preventDefault()
    e.stopPropagation()
    window.gtag("event", "Carousel", {
      event_category: "Opened Fullscreen image",
      event_label: index,
      value: 1,
    })
    this.setState({ showGallery: true, slideToScroll: index })
  }

  hideGallery = () => {
    this.setState({ showGallery: false })
  }

  toggleShowFullContent = () => {
    this.setState(state => {
      return { showFullContent: !state.showFullContent }
    })
  }

  render() {
    let description = this.state.desc
    let { gameName } = this.props

    let {
      showGallery,
      slideToScroll,
      allContent,
      excerptContent,
      showFullContent,
      imageGallery,
      loaded,
      DescriptionContent,
      ReadMoreToggle,
      GalleryContent,
    } = this.state

    if (!loaded) {
      return false
    }
    return (
      <>
        <StaticQuery
          query={Data}
          render={data => {
            const images = getCarouselImages(data)
            if (this.context.cms && gameName) {
              const getAllPosts = data.allGhostPost.edges
              const currentPost = getAllPosts.filter(
                item => item.node.slug === gameName
              )
              if (
                typeof currentPost !== "undefined" &&
                currentPost.length > 0
              ) {
                const getContent = currentPost.map(function (i) {
                  return i.node.html
                })

                const getAllContent = document.createElement("div")

                getAllContent.innerHTML = getContent[0]

                const allFigures = getAllContent.getElementsByTagName("figure")
                const imgGallery = []
                const imgs = getAllContent.querySelectorAll("figure img")

                imgs.forEach(function (e, i) {
                  imgGallery.push({
                    index: i,
                    image: e.src,
                  })
                })
                imageGallery = imgGallery

                while (allFigures[0])
                  allFigures[0].parentNode.removeChild(allFigures[0])

                const contentWithoutGallery = getAllContent.innerHTML

                const getexcerptContent =
                  getAllContent?.querySelectorAll("p")[0].innerHTML

                excerptContent = getexcerptContent

                allContent = contentWithoutGallery

                DescriptionContent = !showFullContent ? (
                  <div dangerouslySetInnerHTML={{ __html: excerptContent }} />
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: allContent }} />
                )

                ReadMoreToggle = !showFullContent ? "READ MORE" : "COLLAPSE"

                GalleryContent = showGallery ? (
                  <Gallery
                    slideToScroll={slideToScroll}
                    hideGallery={this.hideGallery}
                    fbEventAndShowModal={this.props.fbEventAndShowModal}
                    imageData={imageGallery}
                  />
                ) : null
              }
            }

            return this.context.cms && allContent ? (
              <div className="container padTop1">
                <div className="galleryBlock">
                  <div
                    className="siema"
                    style={{
                      minHeight: "155px",
                      maxHeight: "200px",
                      overflow: "hidden",
                    }}
                  >
                    {imageGallery.map(({ image }, index) => {
                      return (
                        <div
                          className="padR4"
                          key={index}
                          onClick={e => this.showGallery(e, index)}
                          onKeyDown={e => this.showGallery(e, index)}
                          role="button"
                          tabIndex="0"
                        >
                          <img src={image} alt="slide" />
                        </div>
                      )
                    })}
                  </div>
                </div>

                <div className="content is-size-7">{DescriptionContent}</div>
                {allContent && (
                  <div className="is-capitalized playColor has-text-weight-semibold is-size-7 has-text-centered readMoreToggleWrap">
                    <span
                      onClick={this.toggleShowFullContent}
                      onKeyDown={this.toggleShowFullContent}
                      role="button"
                      tabIndex="0"
                      className="is-pointer"
                    >
                      {ReadMoreToggle}
                    </span>
                  </div>
                )}
                {GalleryContent}
              </div>
            ) : (
              <>
                <div className="container padTop1">
                  <div
                    className="siema"
                    style={{
                      minHeight: "155px",
                      maxHeight: "200px",
                      overflow: "hidden",
                    }}
                  >
                    {images
                      .filter(({ path }) =>
                        this.context.carouselImages.includes(path)
                      )
                      .map(({ path, img }, index) => {
                        return (
                          <div
                            className="padR4"
                            key={index}
                            onClick={e => this.showGallery(e, index)}
                            onKeyDown={e => this.showGallery(e, index)}
                            role="button"
                            tabIndex="0"
                          >
                            <GatsbyImage image={img} alt="" />{" "}
                          </div>
                        )
                      })}
                  </div>
                  <div className=" is-size-7">
                    <p>{description}</p>
                    <br />
                  </div>
                  <Description />
                </div>
                {showGallery ? (
                  <Gallery
                    slideToScroll={slideToScroll}
                    hideGallery={this.hideGallery}
                    fbEventAndShowModal={this.props.fbEventAndShowModal}
                  />
                ) : null}
              </>
            )
          }}
        />
      </>
    )
  }
}

export default withLocation(Carousel)
