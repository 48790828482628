import React, { useContext } from "react"
import ConfigContext from "./utils/configContext"
import PRIVACY_POLICY_LINK from "./utils/constants"

const Disclaimer = () => {
  const context = useContext(ConfigContext)
  return (
    <div>
      <hr />
      <p style={{ marginTop: 12 }} className="has-text-grey is-size-7 margBot7">
        This game involves an element of financial risk and may be addictive.
        Please play responsibly and at your own risk.
        {context.disclaimer || null}
      </p>
      {context.showPrivacyPolicy && (
        <a href={PRIVACY_POLICY_LINK} target="_blank" rel="noreferrer">
          <p className="playColor has-text-weight-semibold is-size-7 has-text-centered">
            Privacy Policy
          </p>
        </a>
      )}
    </div>
  )
}

export default Disclaimer
