import React from "react"
import { v4 as uuidv4 } from "uuid"
import Header from "./Header"
import "../styles/buefy.css"
import "../styles/header.css"
import Carousel from "./Carousel"
import ReviewsList from "./ReviewsList"
import Popup from "./Popup"
import AppInfo from "./AppInfo"
import { generateUrl } from "./utils/generateUrl"
import ConfigContext from "./utils/configContext"

class App extends React.Component {
  static contextType = ConfigContext

  state = {
    reviews: [],
    reviewsByRating: {},
    isOpen: false,
    scrollHeight: 0,
    initialMount: true,
    installUrl: "",
    gameType: "",
  }

  toggleModal = () => {
    this.setState(state => ({ isOpen: !state.isOpen }))
  }

  fbEventAndShowModal = eventSource => {
    window.fbq("trackCustom", "Login")
    window.gtag("event", "Download APK", {
      event_category: "Downloaded",
      event_label: eventSource,
      value: 1,
    })
    this.setState(state => ({ isOpen: !state.isOpen }))
  }

  async componentDidMount() {
    let self = this
    this.setState({
      ...this.state,
      installUrl: generateUrl(this.context.installUrl, this.context.forceUse),
    })

    window.onscroll = function () {
      if (self.state.initialMount && window.pageYOffset > 5) {
        self.setState({ scrollHeight: window.pageYOffset, initialMount: false })
      }
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 200 && this.state.progress !== 200) {
        this.setState({ progress: 200 })
      }
      if (window.pageYOffset < 200 && this.state.progress !== 0) {
        this.setState({ progress: 0 })
      }
    })

    const { search } = window.location
    const params = new URLSearchParams(search)
    const pid = params.get("pid")

    if (pid !== "taboola_int") {
      // open free gems popup after 30 seconds
      const popupTimer = setTimeout(() => {
        this.setState({ isPopupOpen: true })
      }, 30 * 1000)
      this.setState({ freeGemsTimer: popupTimer })
    }

    // check for utm_game in url
    const siteurl = new URL(window.location.href)
    const checkGame = siteurl.searchParams.get("utm_game")
    if (checkGame) {
      this.setState({ gameType: checkGame, gameName: "landing-" + checkGame })
    }
  }

  componentWillUnmount() {
    window.onscroll = null
    if (this.state.freeGemsTimer) {
      clearTimeout(this.state.freeGemsTimer)
    }
  }

  filterReviews = filter => {
    let { reviewsByRating, reviews } = this.state
    let filteredReviews
    let remainingReviews

    filteredReviews =
      (reviewsByRating[filter.toString()] && [
        ...reviewsByRating[filter.toString()],
      ]) ||
      []

    remainingReviews = reviews.filter(
      review => review.stars !== parseInt(filter)
    )

    filteredReviews = [...filteredReviews, ...remainingReviews]
    this.setState({ reviews: filteredReviews })
  }

  closePopup = () => {
    this.setState({ isPopupOpen: false })
  }

  render() {
    let {
      scrollHeight,
      isPopupOpen,
      progress,
      installUrl,
      gameType,
      gameName,
    } = this.state
    let transitionClass = progress >= 200 ? "fade-in" : "fade-out"
    return (
      <>
        {this.state.isOpen ? (
          <div className="custom-modal">
            <iframe
              src={`https://player.vimeo.com/video/${this.context.vimeoVideoId}?title=0&byline=0&portrait=0&autoplay=1&loop=1&background=1`}
              width="640"
              height="1138"
              frameBorder="0"
              allowFullScreen={true}
              title="install video"
            />
            <span
              className="is-large"
              onClick={this.toggleModal}
              onKeyDown={this.toggleModal}
              role="button"
              tabIndex="0"
            >
              <strong style={{ color: "#fff" }} className="mdi ">
                X
              </strong>
            </span>
          </div>
        ) : null}
        <div id="app" className={`${this.state.isOpen ? "no-scrolling" : ""}`}>
          <Header
            className="container"
            fbEventAndShowModal={this.fbEventAndShowModal}
            gameName={gameName}
          />
          <hr className="bg-transparent" />

          <Carousel
            fbEventAndShowModal={this.fbEventAndShowModal}
            gameName={gameName}
          />
          <hr />
          <ReviewsList
            gameName={gameType || this.context.staticReviews}
            scrollHeight={scrollHeight}
          />
          {this.context.showAppInfo && (
            <>
              <hr />
              <AppInfo />
            </>
          )}
        </div>
        <div
          className={`install-button-bottom sticky-install-button ${transitionClass}`}
        >
          <a
            onClick={() => {
              this.fbEventAndShowModal("Sticky Install Button")
            }}
            href={installUrl}
          >
            <button className="weight-500">INSTALL</button>
          </a>
        </div>

        {isPopupOpen && (
          <Popup
            closePopup={this.closePopup}
            installUrl={installUrl}
            fbEventAndShowModal={this.fbEventAndShowModal}
          />
        )}
      </>
    )
  }
}

export default App
